<template>
<div>
    <div class="lead-search-area">
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-4">
                <div class="lead-search">
                    <div class="form-group">
                        <label for="type">Type</label>
                        <select class="form-control" id="type" v-model="filters_.type">
                            <option value="">All</option>
                            <option value="1">Deposit</option>
                            <option value="0">Pending</option>
                            <option value="2">Processed</option>

                        </select>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3">
                <div class="lead-search">
                    <div class="form-group">
                        <label>Start</label>
                        <Datepicker v-model="filters_.start_date" :enableTimePicker="false" :autoApply="true" placeholder="Select Start Date" inputClassName="form-control date-controll "></Datepicker>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-3">
                <div class="lead-search">
                    <div class="form-group">
                        <label>End</label>
                        <Datepicker v-model="filters_.end_date" :enableTimePicker="false" :autoApply="true" placeholder="Select End Date" inputClassName="form-control date-controll "></Datepicker>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-2">
                <div class="lead-search">
                    <a class="refresh btn-block btn" data-bs-toggle="modal" data-bs-target="#exampleModal"  href="javascript:;">WithDraw</a>
                </div>
            </div>
        </div>
    </div>
    <!-- ounter-grid -->
    <div class="counter-grid">
        <div class="row">

            <div class="col-sm-6 col-md-6 col-lg-4">
                <div class="counters">
                    <div class="thamnils">
                        <svg class="icons mt-1" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 20 20" fill="none">
                            <path d="M4.65274 7.91668L2.17452 9.26843C1.59547 9.58428 1.59547 10.4157 2.17452 10.7316L4.65274 12.0833M4.65274 7.91668L9.20187 10.398C9.69934 10.6694 10.3006 10.6694 10.798 10.398L15.3472 7.91668M4.65274 7.91668L2.17452 6.56492C1.59547 6.24907 1.59547 5.41761 2.17452 5.10176L9.20188 1.26866C9.69934 0.997319 10.3006 0.997319 10.798 1.26866L17.8254 5.10176C18.4045 5.41761 18.4045 6.24907 17.8254 6.56492L15.3472 7.91668M15.3472 7.91668L17.8254 9.26843C18.4045 9.58428 18.4045 10.4157 17.8254 10.7316L15.3472 12.0833M4.65274 12.0833L2.17452 13.4351C1.59547 13.7509 1.59547 14.5824 2.17452 14.8983L9.20187 18.7314C9.69934 19.0027 10.3006 19.0027 10.798 18.7314L17.8254 14.8983C18.4045 14.5824 18.4045 13.7509 17.8254 13.4351L15.3472 12.0833M4.65274 12.0833L9.20187 14.5647C9.69934 14.836 10.3006 14.836 10.798 14.5647L15.3472 12.0833" stroke="#6e317a" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                    </div>
                    <div class="counter-num">
                        <p class="mb-0">Deposit Funds</p>
                        <h4>{{stats.deposit_amount}}</h4>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-6 col-lg-4">
                <div class="counters">
                    <div class="thamnils">
                        <svg class="icons mt-1" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 20 20" fill="none">
                            <path d="M4.65274 7.91668L2.17452 9.26843C1.59547 9.58428 1.59547 10.4157 2.17452 10.7316L4.65274 12.0833M4.65274 7.91668L9.20187 10.398C9.69934 10.6694 10.3006 10.6694 10.798 10.398L15.3472 7.91668M4.65274 7.91668L2.17452 6.56492C1.59547 6.24907 1.59547 5.41761 2.17452 5.10176L9.20188 1.26866C9.69934 0.997319 10.3006 0.997319 10.798 1.26866L17.8254 5.10176C18.4045 5.41761 18.4045 6.24907 17.8254 6.56492L15.3472 7.91668M15.3472 7.91668L17.8254 9.26843C18.4045 9.58428 18.4045 10.4157 17.8254 10.7316L15.3472 12.0833M4.65274 12.0833L2.17452 13.4351C1.59547 13.7509 1.59547 14.5824 2.17452 14.8983L9.20187 18.7314C9.69934 19.0027 10.3006 19.0027 10.798 18.7314L17.8254 14.8983C18.4045 14.5824 18.4045 13.7509 17.8254 13.4351L15.3472 12.0833M4.65274 12.0833L9.20187 14.5647C9.69934 14.836 10.3006 14.836 10.798 14.5647L15.3472 12.0833" stroke="#6e317a" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                    </div>
                    <div class="counter-num">
                        <p class="mb-0">On the way Funds</p>
                        <h4>{{stats.processed_amount}}</h4>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-md-6 col-lg-4">
                <div class="counters">
                    <div class="thamnils">
                        <svg class="icons mt-1" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 20 20" fill="none">
                            <path d="M4.65274 7.91668L2.17452 9.26843C1.59547 9.58428 1.59547 10.4157 2.17452 10.7316L4.65274 12.0833M4.65274 7.91668L9.20187 10.398C9.69934 10.6694 10.3006 10.6694 10.798 10.398L15.3472 7.91668M4.65274 7.91668L2.17452 6.56492C1.59547 6.24907 1.59547 5.41761 2.17452 5.10176L9.20188 1.26866C9.69934 0.997319 10.3006 0.997319 10.798 1.26866L17.8254 5.10176C18.4045 5.41761 18.4045 6.24907 17.8254 6.56492L15.3472 7.91668M15.3472 7.91668L17.8254 9.26843C18.4045 9.58428 18.4045 10.4157 17.8254 10.7316L15.3472 12.0833M4.65274 12.0833L2.17452 13.4351C1.59547 13.7509 1.59547 14.5824 2.17452 14.8983L9.20187 18.7314C9.69934 19.0027 10.3006 19.0027 10.798 18.7314L17.8254 14.8983C18.4045 14.5824 18.4045 13.7509 17.8254 13.4351L15.3472 12.0833M4.65274 12.0833L9.20187 14.5647C9.69934 14.836 10.3006 14.836 10.798 14.5647L15.3472 12.0833" stroke="#6e317a" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                    </div>
                    <div class="counter-num">
                        <p class="mb-0">Pending Funds</p>
                        <h4>{{stats.pending_amount}}</h4>
                    </div>
                </div>
            </div>



        </div>
    </div>
    <!-- ounter-grid -->


    <div class="leads-table-area addcustomer-table">
        <table id="payout_table" class="display">
            <thead>
                <tr>
                    <th>Account Type</th>
                    <th>Payout Amount</th>
                    <th>Status</th>
                    <th>Withdraw Date</th>
                    <th>Arrival Date</th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>

    <!-- WIthdraw -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog " role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Withdraw </h5>
                    <a class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><img class="img-fluid" src="@/assets/images/icons/Closex.svg" alt=""></span>
                    </a>
                </div>
                <div class="modal-body">
                    <div class="add-trades">
                        <form class="test" method="post">

                            <div class="ms-5">
                                <h4 class="modalText">Account Balance: <span class="text-dark fw-bolder balance">${{available_amount}}</span></h4>
                            </div>

                            <div class="row mb-4 mt-5">
                                <label for="horizontal-email-input" class="col-sm-6 col-form-label font-size-16 modalText">Amount to Withdraw:</label>
                                <div class="col-sm-6">
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <input type="text"  @keypress="isNumber($event)" v-model="payout_.amountToBeWithdraw" class="form-control" placeholder=" 100.00">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                    <a href="#" data-bs-dismiss="modal" aria-label="Cancel" class="add-btn">Cancel</a>
                    <a href="#" v-on:click.prevent="payout" class="rem-btn">Withdraw</a>
                </div>
                <p class="p-3 note">
                    When you click the withdraw button, automatically the transaction will be processed. It may take 2 - 3 business days to receive your money. This depends on your bank.
                    And you agree to our <a href="#">Terms and Conditions</a> and <a href="#">Privacy Policy</a>.
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "axios";
import Datepicker from '@vuepic/vue-datepicker';
import { useToast } from "vue-toastification";
import '@vuepic/vue-datepicker/dist/main.css'
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
//import moment from "moment";

export default {
    name: "stripePayOut",
    setup () {
        const toast = useToast();
        return { toast }
    },
    components: {
        Datepicker
    },
    data() {
        return {
            filters_:{
                start_date:null,
                end_date:null,
                employee:'',
                type:''
            },
            stats:{},
            payout_:
            {
                amountToBeWithdraw:null,
            },
            available_amount: 0,

        };
    },
    created() {
        this.stripeAccountDetails();
    },
    methods: {
        stripeAccountDetails(){
            let thiss = this
            axios.get('connect_account_details').then(function (response) {
                thiss.available_amount = response.data.data.data;

            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message);
                }
            });
        },
        payout(){
            let thiss = this
            if(thiss.payout_.amountToBeWithdraw > thiss.available_amount)
            {
                thiss.toast.error("You don't have enough funds to withdraw. Maximum amount available to withdraw $"+thiss.available_amount,{position: "top-right"});
                return;
            }
            if(thiss.available_amount > 0){
                axios.post('payout',this.payout_).then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.toast.success(response.data.message);

                    }
                    $('#exampleModal').modal('hide');
                    $("#payout_table").dataTable().fnDraw();
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message);
                        thiss.toast.error(error.response.data.message);
                    }
                });
            }else{
                thiss.toast.error("Please enter minimum amount $0.5",{position: "top-right"});
                return;
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        filterData() {
            $("#payout_table").dataTable().fnDraw();
        }
    },
    mounted() {
        let thiss = this;
        $("#payout_table")
        //.addClass("nowrap")
        .dataTable({
            //order: [[7, "desc"]],
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            ajax: {
                url: axios.defaults.baseURL + "dt_payout",
                type: "POST",
                data: function (d) {
                    d.start_date = thiss.filters_.start_date
                    d.end_date = thiss.filters_.end_date
                    d.type = thiss.filters_.type
                    d.business_id = thiss.$storage.getStorageSync('business').active_business.id
                },
                headers: {
                    Authorization: axios.defaults.headers.common['Authorization'],
                }
            },
            searching: false,
            drawCallback: function (response) {
                //alert( 'Table redrawn '+new Date() );
                thiss.stats = response.json.stats
                console.log(response.json.stats)
            },
            columnDefs: [
                {
                    targets: [2, 4],
                    orderable: false,
                },
            ]
        });
    },
};
</script>
<style scoped>
@import "../../../assets/css/dashboard/dataTables.css";
</style>
<style>
.lead-search .date-controll{
    background-color:#fff !important;
    padding-left: 30px;
}
.dp__main{
    width: 100%;
}
.dp__input_icon{
    left: 13px;
}
.dp__clear_icon{
    right: -15px;
}
.counter-num h4{
    font-size: 25px;
}
.modal-header img{
    filter: brightness(0) invert(1);
}
.modal-header .close{
    background: black;
}
.modalText{
    color: #3f3f3f
}
.balance{
    font-size:30px;
    color:#078c26 !important
}
.note{
    background:#F8f9fA;
    font-size: 11px;
}
.note a{
    font-size: 11px;
    text-decoration: none;
    font-weight: bold;
}
.note a:hover{
    color: #6e317a !important;
}
</style>
<template>
    <AppContainer>
        <StripePayOut/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import StripePayOut from '@/components/dashboard/stripe/stripePayOut.vue'
export default {
  name: 'stripePayOut',
  components: {
    AppContainer,
    StripePayOut
  }
}
</script>